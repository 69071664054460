import React, { useState } from 'react';
import PropTypes, { number } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import formStyles from '../../../styles/input.module.scss';
import stepStyles from '../../wrappers/Steps/style.module.scss';
import Loqate from '../Loqate';
import Address from '../Address';
import Button from '../Button';

import getMageData, { getStoreData } from '../../../helpers/MageStorage';

const AddressForm = (props) => {
  const {
    onSubmit: submitAddress,
    onEdit,
    address,
    addressFields,
    isSet,
    name,
    canEditAddress,
    countries,
    isClickAndCollect,
    customerAddresses,
    isShippingAddress,
    additionalText,
  } = props;

  const [manualAddress, setManualAddress] = useState(false);
  const [regionSelect, setRegionSelect] = useState(false);
  const [regions, setRegions] = useState([]);

  const fieldClasses = (field) => {
    if (
      field.name === 'firstname'
      || field.name === 'lastname'
      || field.name === 'telephone') {
      return `${formStyles.field} ${formStyles.required}`;
    }

    if (!manualAddress || !canEditAddress) {
      return `${formStyles.field} ${formStyles.fieldHidden}`;
    }

    return formStyles.field;
  };

  // Don't allow user to select an address that doesn't match the postcode entered into the delivery modal
  const filterSavedAddresses = customerAddresses.filter((thisAddress) => {
    const deliveryData = getMageData();
    const { deliveryPostCode = '' } = deliveryData;

    return (thisAddress.postcode.trim() === deliveryPostCode.trim()) ? thisAddress : null;
  });

  const setSavedAddress = (id) => {
    const selectedAddress = customerAddresses.find((savedAddress) => savedAddress.id === id);

    let region;
    if (typeof selectedAddress.region === 'string') {
      region = selectedAddress.region;
    } else if (selectedAddress.region) {
      region = selectedAddress.region.region;
    } else {
      region = '';
    }

    const postAddress = {
      firstname: selectedAddress.firstname,
      lastname: selectedAddress.lastname,
      telephone: selectedAddress.telephone,
      city: selectedAddress.city,
      company: selectedAddress.company,
      country_id: selectedAddress.country_id,
      postcode: selectedAddress.postcode,
      region,
      street: selectedAddress.street,
    };

    submitAddress(postAddress);
  };

  // For standard shipping grab the postcode the user put into the modal
  // and use for the shipping address
  let disablePostcodeField = false;

  if (!isClickAndCollect && isShippingAddress) {
    const storeData = getStoreData();
    const deliveryData = getMageData();
    let shippingPostcode;

    // eslint-disable-next-line no-prototype-builtins
    if (storeData && deliveryData.hasOwnProperty('deliveryPostCode')) {
      shippingPostcode = deliveryData.deliveryPostCode;

      if (shippingPostcode) {
        disablePostcodeField = true;
        address.postcode = shippingPostcode;
      }
    }
  }

  return (
    <div>
      <Formik
        initialValues={address}
        enableReinitialize
        validate={(values) => {
          const errors = {};

          addressFields.forEach((field) => {
            const { required = false, name: fieldName, displayName: displayName} = field;

            if (fieldName === 'street' && !values[fieldName][0]) {
              errors[fieldName] = 'The first line of your street is required';
            } else if (required && !values[fieldName]) {
              errors[fieldName] = `${displayName} is required`;
            }

            const { genecheckout: { storeCode = 'kkuk' } = {} } = window;

            if (storeCode === 'kkuk' && fieldName === 'telephone') {
              const regex = /^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/;
              const noMatch = (values[fieldName].match(regex) === null);

              if (noMatch || values[fieldName].length < 9) {
                errors[fieldName] = 'Please enter a valid UK number';
              }
            }
          });

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            submitAddress(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          validateForm,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>

            {/*<Address title={name} address={values} isClickAndCollect additionalText={additionalText} />*/}
            {/*{isSet && (*/}
            {/*  <div className={stepStyles.step__edit}>*/}
            {/*    <button className="linkAstext" type="button" onClick={() => onEdit()}>*/}
            {/*      {isClickAndCollect && (<>Edit Your Details</>)}*/}
            {/*      {!isClickAndCollect && (<>Edit Address</>)}*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*)}*/}

            {!isSet && (
              <div className={stepStyles.step__edit}>
                {isClickAndCollect && (<h5 className={formStyles.title}>Contact Details</h5>)}

                {(filterSavedAddresses.length > 0) && (
                  <div className={formStyles.field} key="saved_address">
                    <label className={formStyles.label} htmlFor="saved_address">
                      Use saved address
                    </label>
                    <div className={formStyles.control}>
                      <Field
                        name="saved_address"
                        onChange={async (e) => {
                          const { value } = e.target;
                          setSavedAddress(parseInt(value, 10));
                        }}
                        onBlur={handleBlur}
                        as="select"
                      >
                        <option>Select a saved address...</option>
                        {filterSavedAddresses.map((savedAddress) => (
                          <option key={savedAddress.id} value={savedAddress.id}>
                            {`${savedAddress.firstname} ${savedAddress.lastname}, ${savedAddress.street[0]}`}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                )}

                {addressFields.map((field) => {
                  if (field.name === 'street') {
                    return (
                      <div className={fieldClasses(field)} key={field.name}>
                        <label className={formStyles.label} htmlFor={field.name}>{field.displayName}</label>
                        <div className={formStyles.control}>
                          <Field className={(touched && errors.street) ? formStyles.fieldError : ''} type={field.type} name={`${field.name}[0]`} />
                        </div>
                        <div className={formStyles.control}>
                          <Field type={field.type} name={`${field.name}[1]`} />
                        </div>
                        {errors.street ? (
                          <div className="Alert AlertError">{errors.street}</div>
                        ) : null}
                      </div>
                    );
                  }

                  if (field.name === 'country_id') {
                    if (countries.length > 1) {
                        return (
                            <div className={fieldClasses(field)} key={field.name}>
                                <label className={formStyles.label} htmlFor={field.name}>
                                    {field.displayName}
                                </label>
                                <div className={formStyles.control}>
                                    <Field
                                        type={field.type}
                                        name={field.name}
                                        onChange={async (e) => {
                                            const {value} = e.target;
                                            const selectedCountry = countries.filter((country) => country.id === value)[0];
                                            const {available_regions: availableRegions} = selectedCountry;

                                            // Clear region
                                            setFieldValue('region_code', '');
                                            setFieldValue('region', '');

                                            if (availableRegions && availableRegions.length) {
                                                setFieldValue('country_id', value);
                                                setRegions(availableRegions);
                                                setRegionSelect(true);
                                                return;
                                            }

                                            setRegions([]);
                                            setFieldValue('country_id', value);
                                            setRegionSelect(false);
                                        }}
                                        onBlur={handleBlur}
                                        value={values[field.name]}
                                        as="select"
                                    >
                                        <option>Choose your country...</option>
                                        {countries.map((country) => (
                                            <option key={country.two_letter_abbreviation}
                                                    value={country.two_letter_abbreviation}>
                                                {country.full_name_english}
                                            </option>
                                        ))}
                                    </Field>
                                    {errors[field.name] ? (
                                        <div className="Alert AlertError">{errors[field.name]}</div>
                                    ) : null}
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div className={fieldClasses(field)} key={field.name}>
                            <label className={formStyles.label} htmlFor={field.name}>
                                {field.displayName}
                            </label>
                            <div className={formStyles.control}>
                                <Field
                                    type='text'
                                    disabled
                                    name={field.name}
                                    value={countries[0].two_letter_abbreviation}
                                />
                            </div>
                        </div>
                    );

                  }

                  // Don't show Region Code field
                  if (field.name === 'region_code') return false;

                  if (field.name === 'region') {
                    return (
                      <div className={fieldClasses(field)} key={field.name}>
                        <label className={formStyles.label} htmlFor={field.name}>
                          {field.displayName}
                        </label>
                        <div className={formStyles.control}>
                          {(regionSelect) && (
                            <>
                              <Field
                                type={field.type}
                                name={field.name}
                                onChange={async (e) => {
                                  const { value } = e.target;
                                  const { code: regionCode = '' } = regions.filter((thisRegion) => thisRegion.name === value)[0];
                                  setFieldValue('region', value);
                                  setFieldValue('region_code', regionCode);
                                }}
                                className={(touched && errors[field.name]) ? formStyles.fieldError : ''}
                                onBlur={handleBlur}
                                value={values[field.name]}
                                as="select"
                              >
                                {regions.map((region) => (
                                  <option value={region.name}>
                                    {region.name}
                                  </option>
                                ))}
                              </Field>
                            </>
                          )}
                          {(!regionSelect) && (
                            <Field
                              type={field.type}
                              name={field.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values[field.name]}
                            />
                          )}
                          {errors[field.name] ? (
                            <div className="Alert AlertError">{errors[field.name]}</div>
                          ) : null}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className={fieldClasses(field)} key={field.name}>
                      <label className={formStyles.label} htmlFor={field.name}>
                        {field.displayName} {field.name === 'telephone' && (
                            <small>
                                (used only to provide you order updates) &nbsp;
                            </small>
                        )}
                      </label>
                      {(isShippingAddress && field.name === 'postcode') && (
                        <p><small>Your postcode cannot be changed at this stage.</small></p>
                      )}
                      <div className={formStyles.control}>
                        <Field
                          type={field.type}
                          name={field.name}
                          className={(touched && errors[field.name]) ? formStyles.fieldError : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[field.name]}
                          disabled={field.name === 'postcode' ? disablePostcodeField : false}
                        />
                        {errors[field.name] ? (
                          <div className="Alert AlertError">{errors[field.name]}</div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}

                {(!manualAddress && canEditAddress) && (
                  <>
                    <Loqate
                      values={values}
                      onSelect={submitAddress}
                      validate={validateForm}
                      fixedPostcode={disablePostcodeField}
                    />
                    {/*{errors.postcode ? (*/}
                    {/*  <div className="Alert AlertError">Address required (Postcode Missing)</div>*/}
                    {/*) : null}*/}
                    {/*{errors.city ? (*/}
                    {/*  <div className="Alert AlertError">Address required (City Missing)</div>*/}
                    {/*) : null}*/}
                    {/*{errors.city ? (*/}
                    {/*  <div className="Alert AlertError">Address required (Street Missing)</div>*/}
                    {/*) : null}*/}
                  </>
                )}


                  {canEditAddress && (
                      <>
                      <div className={formStyles.formActions}>
                          or &nbsp;
                        <Button
                          label={manualAddress ? 'Use address finder' : 'Enter address manually'}
                          buttonStyle="link"
                          trigger={() => { setManualAddress(!manualAddress); }}
                        />
                      </div>
                      </>
                  )}


                  {(manualAddress || isClickAndCollect) && (
                      <div className={formStyles.formActions}>
                          <button type="submit" disabled={isSubmitting}>
                              {`Submit ${name}`}
                          </button>
                      </div>
                  )}

              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

AddressForm.propTypes = {
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  isSet: PropTypes.bool,
  address: PropTypes.shape({
    country_id: PropTypes.string,
    postcode: PropTypes.string,
  }),
  addressFields: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  name: PropTypes.string,
  canEditAddress: PropTypes.bool,
  isClickAndCollect: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.shape({
    full_name_english: PropTypes.string,
    two_letter_abbreviation: PropTypes.string,
  })),
  isShippingAddress: PropTypes.bool,
  customerAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      id: PropTypes.number,
      street: PropTypes.arrayOf({
        0: PropTypes.string,
      }),
    }),
  ),
  additionalText: PropTypes.string,
};

AddressForm.defaultProps = {
  onEdit: () => {},
  onSubmit: () => {},
  isSet: false,
  address: {
    country_id: 'GB',
    postcode: '',
  },
  addressFields: [{}],
  name: '',
  canEditAddress: true,
  isClickAndCollect: false,
  countries: [],
  isShippingAddress: false,
  customerAddresses: [],
  additionalText: '',
};

export default AddressForm;
