/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';
import formStyles from '../../../styles/input.module.scss';
import Button from '../Button';

const axios = require('axios');

const { genecheckout: { storeCode = 'kkuk' } = {} } = window;

const Loqate = ({
  onSelect,
  values,
  validate,
  fixedPostcode,
}) => {
  const { genecheckout: { loqateKey } = {} } = window;

  if (!loqateKey) {
    return null;
  }

  const findUrl = 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws';
  const retrieveUrl = 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws';

  const {
    firstname,
    lastname,
    telephone,
    postcode,
  } = values;

  const [addressList, setAddressList] = useState([]);
  const [query, setQuery] = useState('');
  const [forceUpdate, setForceUpdate] = useState(false);
  const [container, setContainer] = useState('');
  const [arrowCounter, setArrowCounter] = useState(0);
  const [showList, setShowList] = useState(false);
  const postcodeInput = useRef(null);
  const [showPostcodeValidationError, setShowPostcodeValidationError] = useState(false);

  const countryLimit = () => {
    if (storeCode === 'kkie') {
      return 'IE';
    }

    return 'GB';
  };

    const getSuggestions = (searchQuery, containerQuery) => {
        console.log('getSuggestions', searchQuery, containerQuery);
        setContainer((containerQuery) ? `&Container=${containerQuery}` : '');
        setQuery(searchQuery);
    };

  useEffect(() => {
    if (query.length > 2) {
      axios.get(
        `${findUrl}?key=${loqateKey}&Countries=${countryLimit()}&Limit=10&Text=${query}${container}`,
      ).then((response) => {
        // If we only have one response in the initial search use that as the base search
        // so the customer has the full list straight away
        if (response.data.Items.length === 1 && response.data.Items[0].Type !== 'Address') {
          setSuggestion(response.data.Items[0], true);
        } else {
          setAddressList(response.data.Items);
        }
      });
    }
  }, [query, forceUpdate]);

  const setAddress = (address) => {
    let countryCode = '';

    if (address.CountryIso2 === 'GG' || address.CountryIso2 === 'IM' || address.CountryIso2 === 'JE') {
      countryCode = 'GB';
    } else {
      countryCode = address.CountryIso2;
    }

    const postAddress = {
      firstname,
      lastname,
      telephone,
      city: address.City,
      company: address.Company,
      country_id: countryCode,
      postcode: address.PostalCode,
      region: address.ProvinceName,
      street: [address.Line1, address.Line2],
    };

    onSelect(postAddress);
    validate();
  };

  const onChange = () => {
    setShowPostcodeValidationError(false);
    setShowList(true);

    if (fixedPostcode) {
      getSuggestions(postcode);
    } else {
      if (postcodeInput.current.value.trim() === '') {
        setShowList(false);
      }
      getSuggestions(postcodeInput.current.value);
    }
  };

  const submitPostcode = () => {
    if (!fixedPostcode) {
      if (!postcodeInput.current.value || postcodeInput.current.value.trim() === '') {
        setShowPostcodeValidationError(true);
        return;
      }
      setShowPostcodeValidationError(false);
    }
    onChange();
  };

  const getAndUseAddress = (id) => {
    axios.get(
      `${retrieveUrl}?key=${loqateKey}&Id=${id}`,
    ).then((response) => {
      if (typeof response.data.Items[0].Error !== 'string') {
        const result = response.data.Items[0];
        setAddress(result);
      }
    });
  };

  const setSuggestion = (item, update = false) => {
      console.log('setSuggestion', item, update);
    setArrowCounter(0);

    setForceUpdate(false);
    setForceUpdate(update);

    // Single Address
    if (item.Type === 'Address') {
      getAndUseAddress(item.Id);
      setShowList(false);
    } else {
      setContainer((item.Id) ? `&Container=${item.Id}` : '');
      setQuery(item.Text);
    }
  };

  const keyboardEvents = (e) => {
    const { keyCode } = e;

    if (keyCode === 40) {
      if (arrowCounter < addressList.length) {
        setArrowCounter(arrowCounter + 1);
      }
    }

    if (keyCode === 38) {
      if (arrowCounter > 0) {
        setArrowCounter(arrowCounter - 1);
      }
    }

    if (keyCode === 13) {
      setSuggestion(addressList[arrowCounter], false);
    }
  };

  return (
    <>
      <div className={styles.Loqate}>
        <label className={formStyles.label} htmlFor="loqate">
            {fixedPostcode && (
                <>Postcode <small>(cannot be edited)</small></>
            )}
            {!fixedPostcode && (
                <>Enter your postcode*</>
            )}

        </label>
        <input
          type="text"
          id="loqate"
          name="loqate"
          ref={postcodeInput}
          onChange={onChange}
          onKeyDown={(e) => { keyboardEvents(e); }}
          autoComplete="off"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          autoComplete="chrome-off"
          // Don't allow change of postcode if it's fixed
          placeholder={fixedPostcode ? postcode : 'Search by street name or postcode...'}
          onClick={onChange}
          disabled={fixedPostcode || false}
          className={showPostcodeValidationError ? formStyles.fieldError : ''}
        />
        {showPostcodeValidationError && (
          <div className={styles.validationError}>
              <div className="Alert AlertError">Postcode is required</div>
          </div>
        )}
      </div>
      <div className={styles.Loqate}>

        <Button trigger={() => { submitPostcode(); }} class={styles.FindButton} label="Find address" iconType="search" buttonStyle="primary loqate" />

        {/*<Button trigger={() => { onChange(); }} class={styles.FindButton} label="Select your address" iconType="expand" buttonStyle="addressSearch" />*/}
        {(addressList.length > 0 && showList) && (
          <ul className={styles.LoqateResults}>
            {addressList.map((item, index) => (
              <li className={(index === arrowCounter) ? styles.itemActive : styles.item}>
                <Button
                  label={`${item.Text} - ${item.Description}`}
                  buttonStyle={`fullWidth link ${item.Type}`}
                  trigger={() => { setSuggestion(item, true); }}
                  iconType={item.Type}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

Loqate.propTypes = {
  onSelect: PropTypes.func,
  validate: PropTypes.func,
  values: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    telephone: PropTypes.string,
    postcode: PropTypes.string,
  }),
  fixedPostcode: PropTypes.bool,
};

Loqate.defaultProps = {
  onSelect: () => {},
  validate: () => {},
  values: {
    firstname: '',
    lastname: '',
    telephone: '',
    postcode: '',
  },
  fixedPostcode: false,
};

export default Loqate;
